import React, { useContext, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";

import CustomModal from './../Modal/index';
import { GeneralContext } from './../../context/GeneralContext';
import { DataContext } from './../../context/DataContext';
import Navigation from './../Navigation/index';
import RoleRoute from './../RoleRoute/index';

import Unauthorized from './../../pages/Unauthorized/index';
import Default from './../../pages/Default/index';
import Dashboard from './../../pages/Dashboard/index';
import Report from './../../pages/Report/index';
import NFT from './../../pages/NFT/index';
import CSV from './../../pages/CSV/index';
import WebcamPage from './../../pages/Webcam/index';
import Login from './../../pages/Login/index';

import './theme.css';

const {
  REACT_APP_STREAMS: envStreams,
  REACT_APP_EXTRA_ROUTES: envExtraRoutes,
  REACT_APP_STAGES: envStages
} = process.env

const Core = () => {

  const { axios } = useContext(GeneralContext);

  const [show, setShow] = useState(false);
  const [stream, setStream] = useState('');
  const [id, setId] = useState('');
  const [stages,] = useState(envStages.split(','));
  const [routes, setRoutes] = useState([]);
  const [extraRoutes, setExtraRoutes] = useState([]);
  const [modalData, setModalData] = useState({ type: '', data: [] });

  const [user, setUser] = useState(secureLocalStorage.getItem("auth")?.user);
  const [role, setRole] = useState(secureLocalStorage.getItem("auth")?.role);
  const [activeRoute, setActiveRoute] = useState("/");

  const handleLogout = () => {
    setRole(null);
    setUser(null);
    secureLocalStorage.setItem("auth", { user: null, role: null });
  }

  const handleLogin = (data) => {
    const { role, ...user } = data;
    secureLocalStorage.setItem("auth", { user, role });
    setUser(user);
    setRole(role);
  }

  const handleClose = () => {
    setModalData({ type: '', data: [] })
    setShow(false)
  };

  const getTransactionsByKey = async (stream, key, type) => {
    try {
      setStream(stream)
      let route = stream;
      switch (type) {
        case 'file':
          route = `${stream}-document-metadata`;
          break;
        case 'maptrace':
        case 'location':
          route = 'location';
          break;
        default:
          break;
      }

      const { error, data: { data } } = await axios.get(`/${route}/transactions-by-key/${key}?page=1&items=1000`);
      if (!error) {
        setId(key)
        setShow(true);
        setModalData({ type, data });
      }
    } catch (err) {
      setModalData({ type: '', data: [] });
    }
  };

  const getNavigationItems = useCallback(async () => {
    if (envStreams) {
      setRoutes(envStreams.split(','));
    } else {
      const { data: { error, data: { streams } } } = await axios.get(`/status`);
      if (!error) {
        setRoutes(streams);
      }
    }
    if (envExtraRoutes) {
      setExtraRoutes(envExtraRoutes.split(','))
    }

  }, [axios]);

  useEffect(() => {
    getNavigationItems();
  }, [getNavigationItems]);

  useEffect(() => { }, [activeRoute])

  return (
    <DataContext.Provider value={{ routes, role, user, activeRoute, setActiveRoute, handleLogin, handleLogout }}>
      <Container fluid className="m-0 p-0">
        <CustomModal show={show} handleClose={handleClose} modalData={modalData} stages={stages} stream={stream} id={id} />
        <Navigation routes={[...routes, ...extraRoutes]} />
        <BrowserRouter>
          <Routes>
            {
              routes.length && routes.map((item, index) => (
                <Route key={`route-${item}-${index}`} exact path={`/${item}`} element={
                  <RoleRoute role={role} roles={['user'].includes(item) ? ['admin'] : ['admin', 'user']} >
                    <Default getTransactionsByKey={getTransactionsByKey} stream={item} />
                  </RoleRoute>
                } />
              ))
            }
            <Route exact path={`/`} element={
              <RoleRoute role={role} roles={["admin", "auditor", "user"]} >
                <Dashboard getTransactionsByKey={getTransactionsByKey} stream={'reports'} />
              </RoleRoute>} />
            <Route exact path={`/nfts`} element={
              <RoleRoute role={role} roles={['admin']} >
                <NFT stream='nfts' />
              </RoleRoute>
            } />
            <Route exact path={`/report`} element={
              <RoleRoute role={role} roles={["admin", "auditor"]} >
                <Report getTransactionsByKey={getTransactionsByKey} stream={'reports'} />
              </RoleRoute>} />
            <Route exact path={`/queue`} element={
              <RoleRoute role={role} roles={["admin", "user"]} >
                <CSV stream='csv' />
              </RoleRoute>} />
            <Route exact path={`/picture`} element={
              <RoleRoute role={role} roles={['admin', 'user']} >
                <WebcamPage />
              </RoleRoute>} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </DataContext.Provider >
  );
};

export default Core;