import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Modal, Dropdown, Button, Row, Col, Form, Toast } from 'react-bootstrap';
import { XSquare } from "react-bootstrap-icons";
import Files from './../../components/Files/index';
import AlertPopup from './../../components/AlertPopup/index';
import Fileupload from './../../components/FileHandling/index';
import { Multiselect } from 'multiselect-react-dropdown';
// import { PDFDocument } from 'pdfjs-dist';
import DragAndDropFileUpload from './../../components/DragAndDropFileHandling/index';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import './theme.css';

const CSV = ({ stream }) => {

    const { axios } = useContext(GeneralContext);
    const { user } = useContext(DataContext);
    const [data, setData] = useState([]);
    const [defaultFilters, setDefaultFilters] = useState({ reportId: "", sampleId: "" });
    const [selectedLabResultData, setSelectedLabResultData] = useState([]);
    const [auditors, setAuditors] = useState([]);
    const [selectedReportData, setSelectedReportData] = useState([]);
    const [documentMetadata, setDocumentMetadata] = useState([]);
    const [documentAccess, setDocumentAccess] = useState([]);
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({ title: '', body: '' });
    const [showToast, setShowToast] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const handleClose = () => {
        setShow(false)
    };

    const handleConfirmationClose = () => {
        setShowConfirmationModal(false)
    };

    const handleAuditorSelect = (e) => {
        const isNone = e.some(({ id }) => (id === 'None'));
        setDocumentAccess(isNone ? e.filter(({ id }) => (id === 'None')) : e);
        setSelectedReportData({ ...selectedReportData, access: isNone ? [] : e })
    }

    const getStreamData = async () => {
        try {
            const { data: { data } } = await axios.get(`/csv/list`);
            setData(data);
        } catch (err) {
            setData(null);
        }
    };

    const getUsers = async () => {
        try {
            const { data: { data } } = await axios.get(`/user?page=1&items=1000`);
            const users = data.map(({ data: { json } }) => json).filter(user => (user.role === 'auditor'));
            setAuditors([...users, { id: "None", address: "", role: "" }]);
        } catch (err) {
            setAuditors(null);
        }
    };


    useEffect(() => {
        const fetchStreamData = async () => {
            await getStreamData();
        }

        fetchStreamData().catch(console.error);
        const fetchUsersData = async () => {
            await getUsers();
        }

        fetchUsersData().catch(console.error);
    }, [])

    const columns = [
        {
            dataField: 'reportId',
            text: 'Report Id',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "10%" }),
            filter: textFilter({
                defaultValue: defaultFilters.reportId
            }),
        },
        {
            dataField: 'date',
            text: 'Date',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "20%" }),
            filter: dateFilter('dd/mm/yyy'),
        },
        {
            dataField: 'sampleId',
            text: 'Sample Id',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "10%" }),
            filter: textFilter({
                defaultValue: defaultFilters.sampleId
            })
        },
        {
            dataField: 'sampleName',
            text: 'Sample Name',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "10%" }),
            filter: textFilter()
        },
        {
            dataField: 'sampleDescription',
            text: 'Sample Description',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "20%" }),
            filter: textFilter()
        },
        {
            dataField: 'action',
            text: 'Mark All Tests',
            formatter: (data, row) => {
                const { id } = row;
                return <Dropdown>
                    <Dropdown.Toggle className='w-100' variant="default" id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item key={`details-dropdown-approve-${id}`} onClick={() => { handleReportAction(row, 'Approved') }}>Approve</Dropdown.Item>
                        <Dropdown.Item key={`details-dropdown-follow-up-${id}`} onClick={() => { handleReportAction(row, 'Follow-up') }}>Follow-up</Dropdown.Item>
                        <Dropdown.Item key={`details-dropdown-refuse-${id}`} onClick={() => { handleReportAction(row, 'Refuse') }}>Refuse</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            },
            headerStyle: () => ({ width: "10%" }),
        },
        {
            dataField: 'action2',
            text: 'Save Report To Chain',
            formatter: (data, row) => {
                const { id } = row;
                const disableReport = row.data.some(result => (result.status === "" || !result.status));
                return (
                    <div className='text-center'>
                        <Button
                            disabled={disableReport}
                            key={`save-all-${id}`}
                            className={`${disableReport ? "btn-secondary disabled" : "btn-info"}`}
                            onClick={() => {
                                !disableReport && handleReportAction(row);
                            }}
                        >
                            Save Report
                        </Button>
                    </div>
                )
            },
            headerStyle: () => ({ width: "10%" }),
        }

    ]

    const expendColumns = [
        {
            dataField: 'date',
            text: 'Date',
            formatter: (data, index) => {
                return data;
            },
            headerStyle: () => ({ width: "10%" }),
            sort: true
        },
        {
            dataField: 'substance',
            text: 'Substance',
            formatter: (data, index) => {
                return data;
            },
            headerStyle: () => ({ width: "10%" }),
            sort: true
        },
        {
            dataField: 'value',
            text: 'Value',
            formatter: (data, index) => {
                return data;
            },
            headerStyle: () => ({ width: "10%" }),
            sort: true
        },
        {
            dataField: 'unit',
            text: 'Unit',
            formatter: (data, index) => {
                return data;
            },
            headerStyle: () => ({ width: "10%" }),
            sort: true
        },
        {
            dataField: 'comment',
            text: 'Comment',
            formatter: (data, index) => {
                return data;
            },
            headerStyle: () => ({ width: "30%" }),
            sort: true
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (data, index) => {
                return data;
            },
            headerStyle: () => ({ width: "20%" }),
            sort: true
        },
        {
            dataField: 'action3',
            text: 'Details',
            formatter: (data, row) => {
                const { id } = row;
                return <Dropdown>
                    <Dropdown.Toggle className='w-100' variant="default" id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item key={`details-dropdown-approve-${id}`} onClick={() => { handleLabResultAction(row, 'Approved') }}>Approve</Dropdown.Item>
                        <Dropdown.Item key={`details-dropdown-follow-up-${id}`} onClick={() => { handleLabResultAction(row, 'Follow-up') }}>Follow-up</Dropdown.Item>
                        <Dropdown.Item key={`details-dropdown-refuse-${id}`} onClick={() => { handleLabResultAction(row, 'Refuse') }}>Refuse</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            },
            headerStyle: () => ({ width: "10%" }),
        }
    ]

    const confirmationColumns = [
        {
            dataField: 'date',
            text: 'Date',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'substance',
            text: 'Substance',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'value',
            text: 'Value',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'unit',
            text: 'Unit',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'comment',
            text: 'Comment',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        }
    ]

    /**
     * 
     * @param {*} payload 
     * @param {*} status 
     */
    const handleReportAction = (payload, status) => {
        setSelectedReportData({ ...payload, data: status ? payload.data.map(e => ({ ...e, status, user: user })) : payload.data });
        reportHasDocument(payload.reportIdSampleId)
        setShowConfirmationModal(true);
    }

    const handleLabResultAction = (data, status) => {
        setSelectedLabResultData({ ...data, user: user, status });
        setShow(true);
    }

    const saveDataToBlockchain = async (payload) => {
        const { id: title } = payload;
        try {
            const { error, data: { data } } = await axios.post(`/reports`, {
                data: {
                    createNFT: false,
                    id: payload.reportIdSampleId,
                    keys: payload.access.map(({ address }) => (address)),
                    payload: { ...payload }
                }
            });
            if (!error) {
                await updateFileData(payload, true);
                setToast({ className: 'success', title: 'Success', body: `${title} report saved successfully !` });
            } else {
                setToast({ className: 'danger', title: 'Error', body: `${title} report not saved !` });
            }
        } catch (error) {
            setToast({ className: 'danger', title: 'Error', body: `${title} report not saved !` });
        }
        setShowToast(true);
    }

    const updateFileData = async (dataToUpload, moveFile = false) => {
        const { error, data: { data: payload } } = await axios.post(`/csv/update`, {
            data: {
                multipleFiles: true,
                moveFile,
                payload: { ...dataToUpload }
            }
        });

        const { id } = payload;
        const updatetTableData = data.map(row => (row.id === id ? moveFile ? false : payload : row)).filter(Boolean);

        setData(updatetTableData);
        setSelectedLabResultData([]);
        setSelectedReportData([]);
    }

    useEffect(() => {
    }, [selectedLabResultData, data, showConfirmationModal, documentMetadata, documentAccess, showToast, defaultFilters])

    useEffect(() => {
        const fetchStreamData = async () => {
            await getStreamData(stream);
        }
        fetchStreamData().catch(console.error);
    }, [selectedReportData])

    const handleFileUpload = async (file, docId = null) => {
        const formData = new FormData();
        const document = file.target ? file.target.files[0] : file;
        const documentId = docId || selectedReportData.reportIdSampleId || document.name;
        formData.append("document", document);
        formData.append("createNFT", false);
        formData.append("id", documentId);
        const { error, data: { data } } = await axios.post(
            `/reports-document-content/form-upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        reportHasDocument(documentId)
    }

    const reportHasDocument = async (key) => {
        const { error, data: { data } } = await axios.get(`/reports-document-metadata/transactions-by-key/${key}?page=1&items=1`);
        setDocumentMetadata(data);
    }

    const submitFileForUpload = async (file) => {
        const formData = new FormData();
        const document = file.target ? file.target.files[0] : file;
        const documentId = selectedReportData.reportIdSampleId || document.name;
        formData.append("document", document);
        formData.append("createNFT", false);
        formData.append("id", documentId);
        const { error, data: { data } } = await axios.post(
            `/reports-document-content/form-upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    const handleFileParse = async (file) => {
        const formData = new FormData();
        const document = file.target ? file.target.files[0] : file;
        const { name } = document;
        formData.append("document", document);
        formData.append("parseMethod", 'xlsxMcColgans');
        const { error, data: { data } } = await axios.post(
            `/csv/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (!error) {
            await getStreamData();
            setToast({ className: 'success', title: 'Success', body: `${name} processed with success ! ${data.length} Reports found` });
        } else {
            setToast({ className: 'danger', title: 'Error', body: `${document} processing failed !` });
        }
        setShowToast(true);
    }

    const handlePDFFilter = async (file) => {
        const formData = new FormData();
        const document = file.target ? file.target.files[0] : file;
        const { name } = document;
        formData.append("document", document);
        formData.append("parseMethod", 'xlsxMcColgans');
        const { error, data: { data: filterData } } = await axios.post(
            `/pdf/read`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (!error) {
            setDefaultFilters(filterData);
            const isAvailableForFiltering = data.some(({ reportId, sampleId }) => (reportId === filterData.reportId && sampleId === filterData.sampleId));
            if (isAvailableForFiltering) {
                await handleFileUpload(file, `${filterData.reportId}-${filterData.sampleId}`);
                setToast({ className: 'success', title: 'Success', body: `${name} processed with success ! ${name} file uploaded to to the blockchain ! Filtering Data for data for report ${filterData.reportId} and sample ${filterData.sampleId}` });
            } else {
                setToast({
                    className: 'success',
                    title: 'Success',
                    body: `${name} processed with success !  Filtering Data for report ${filterData.reportId} and sample ${filterData.sampleId}`
                });
            }
        } else {
            setToast({ className: 'danger', title: 'Error', body: `${document} processing failed !` });
        }
        setShowToast(true);
    }

    const expandRow = {
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        renderer: ({ data }) => {
            return <BootstrapTable
                fluid
                bootstrap4
                keyField='id'
                columns={expendColumns}
                data={data}
            />
        },
        showExpandColumn: true
    };

    return (
        <>
            <div className="d-flex flex-column m-4">
                <AlertPopup showToast={showToast} setShowToast={setShowToast} toast={toast} />
                <Modal size="lg" show={showConfirmationModal} onHide={handleConfirmationClose}>
                    <Modal.Header>
                        <Modal.Title>Confirm Data for saving on Chain</Modal.Title>
                        <XSquare onClick={handleConfirmationClose} size={30} />
                    </Modal.Header>
                    <Modal.Body className='h-100'>
                        <Row>
                            <div className='col-4'>
                                <Form.Group className='col-12'>
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={(e) => { setSelectedReportData({ ...selectedReportData, comment: e.target.value }) }}
                                        className="form-control p-3"
                                        id="comment"
                                        placeholder="Comment"
                                    />
                                </Form.Group>
                                <Form.Group className='col-12 p-3'>
                                    <Form.Label>Visible to Auditors {!documentAccess.length ? 'empty' : "not empty"}</Form.Label>
                                    <Multiselect
                                        options={auditors}
                                        selectedValues={documentAccess}
                                        onSelect={(e) => handleAuditorSelect(e)}
                                        onRemove={(e) => handleAuditorSelect(e)}
                                        displayValue="id"
                                        isObject={true}
                                    />
                                    {!documentAccess.length && <small id="file" className="form-text text-danger"> * auditor selection is mandatory</small>}
                                </Form.Group>
                            </div>
                            <div className="col-lg-8 col-md-8">
                                {
                                    documentMetadata.length
                                        ? <Files className="p-3" pagination={false} stream='reports' items={documentMetadata} />
                                        : <DragAndDropFileUpload className="p-3" id="full-width" handleFileSelect={handleFileUpload} title="PDF" extensions={["PDF"]} isRequired={true} />
                                }
                            </div>
                        </Row>

                        {
                            selectedReportData?.data && <BootstrapTable
                                fluid
                                bootstrap4
                                keyField='id'
                                columns={confirmationColumns}
                                data={selectedReportData.data}
                                filterPosition="top"
                            />

                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Col>
                            <Row className="d-flex justify-content-end flex-row flex-nowrap align-items-center">
                                <Button key={`cancel-on-chain`} className='btn-danger'
                                    onClick={() => {
                                        setSelectedReportData([]);
                                        setShowConfirmationModal(false)
                                    }}
                                >Cancel</Button>
                                <Button
                                    disabled={!documentMetadata.length || !documentAccess.length}
                                    key={`save-on-chain`}
                                    className={`${documentMetadata.length && documentAccess.length ? "btn-info" : "btn-secondary disabled"} m-3`}
                                    onClick={() => {
                                        saveDataToBlockchain({ ...selectedReportData, user: user });
                                        setShowConfirmationModal(false);
                                    }}
                                >Confirm</Button>
                            </Row>
                        </Col>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Save on blockchain</Modal.Title>
                        <XSquare onClick={handleClose} size={30} />
                    </Modal.Header>
                    <Modal.Body className='h-100'>
                        <Form.Group className='col-12'>
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                onChange={(e) => { setSelectedLabResultData({ ...selectedLabResultData, comment: e.target.value }) }}
                                className="form-control p-3"
                                id="comment"
                                placeholder="Comment"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <div onClick={() => {
                            updateFileData({ ...selectedLabResultData, user: user });
                            setShow(false);
                            setShow(false);
                        }} className="btn btn-primary">Submit</div>
                    </Modal.Footer>
                </Modal>
                <div className='row'>
                    <div className='col-6'>
                        <DragAndDropFileUpload id="full-width" handleFileSelect={handleFileParse} title="XLS or XLSX" extensions={["XLS", "XLSX"]} />
                    </div>
                    <div className='col-6'>
                        <DragAndDropFileUpload id="full-width" handleFileSelect={handlePDFFilter} title="PDF" extensions={["PDF"]} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div>
                            {
                                (data && data.length) ? <BootstrapTable
                                    fluid
                                    bootstrap4
                                    keyField='id'
                                    columns={columns}
                                    data={data}
                                    filter={filterFactory()}
                                    filterPosition="top"
                                    pagination={paginationFactory()}
                                    expandRow={expandRow}
                                /> : (
                                    <div className="no-data p-4 m-4">
                                        <div className="alert alert-info text-center">
                                            No data available
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default CSV;