import React, { useContext, useEffect, useState } from 'react';

import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import { GeneralContext } from '../../context/GeneralContext';

import './theme.css';
import './mapgl.css';

const MapView = ({ markers, route, port, zoom, mapType }) => {

    const [viewport,] = useState({ ...port, zoom });
    const [showPopup,] = useState(true);
    const { env } = useContext(GeneralContext);

    useEffect(() => {
    }, [showPopup])

    const handleRoute = () => {
        return route ?
            (
                <Source id="data" type="geojson" data={{ ...route }}>
                    <Layer
                        id="lineLayer"
                        type="line"
                        source="Data"
                        layout={{ "line-join": "round", "line-cap": "round" }}
                        paint={{ "line-color": "rgba(220,20,60, 1)", "line-width": 1 }}
                    />
                </Source>
            ) : <></>
    }

    const handleMarker = () => {
        return markers ?
            markers.map((marker, index) => {
                return (
                    <>
                        <Popup
                            latitude={marker.lat}
                            longitude={marker.lon}
                            closeOnClick={false}
                            anchor="bottom">
                            <ul>
                                <li><strong>Latitude : </strong>{marker.lat}</li>
                                <li><strong>Longitude : </strong>{marker.lon}</li>
                            </ul>
                        </Popup>
                        <Marker
                            style={{ display: 'block' }}
                            key={`marker-${index}`}
                            latitude={marker.lat}
                            longitude={marker.lon}
                            anchor="top"
                            className="custom-map-marker"
                        >
                            <div className={marker.type} />
                        </Marker>
                    </>
                );
            }) : <></>;
    }

    return (
        <Map
            mapboxAccessToken={env.maps}
            mapStyle={mapType}
            initialViewState={viewport}
        >
            {handleRoute()}
            {handleMarker()}
        </Map>
    );
}

export default MapView