import { FileUploader } from "react-drag-drop-files";
import "./theme.css";

const DragAndDropFileUpload = ({ className, handleFileSelect, title, extensions, id = "form-content", isRequired = false }) => {

    return (
        <div className={`${className} wrapper`}>
            <div id={id}>
                <div className="form-group">
                    <label>{title}</label>
                    <FileUploader
                        isRequired={isRequired}
                        multiple={false}
                        handleChange={handleFileSelect}
                        name="file"
                        types={extensions}
                    />
                    {isRequired && <small id="file" className="form-text text-danger">* {title} file is required</small>}
                </div>
            </div>
        </div>
    );
}

export default DragAndDropFileUpload
