import { useState, useEffect } from "react";
import Webcam from "react-webcam";

const WebcamPage = () => {

    const [capture, setCapture] = useState(null);

    useEffect(() => { }, [capture])

    return (
        <div className="main-content-container container-fluid p-4">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <Webcam
                        imageSmoothing={true}
                        screenshotQuality={1}
                        screenshotFormat="image/webp"
                        className="w-100">
                        {({ getScreenshot }) => (<div onClick={() => setCapture(getScreenshot())}>Capture photo</div>)}
                    </Webcam>
                </div>
                <div className="col-lg-6 col-md-6">
                    {capture && <img alt='Capture' src={capture} />}
                </div>
            </div>
        </div>
    )
}

export default WebcamPage;