import React, { useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import './theme.css';

const AlertPopup = ({ setShowToast, showToast, toast: { className, title, body } }) => {

    useEffect(() => {
        if (showToast) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [showToast]);

    return (
        <Toast onClick={() => { setShowToast(false) }} show={showToast} className='custom-toast'>
            <Toast.Header closeButton={false} className={`alert-${className}`}>
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body className="alert-default">{body}</Toast.Body>
        </Toast>

    );
}

export default AlertPopup;