import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactJsonViewCompare from 'react-json-view-compare';
import Timeline from './../Timeline/index';
import History from './../History/index';
import MapView from './../Map/index';
import Files from './../Files/index';
import { XSquare } from "react-bootstrap-icons";
import './theme.css';

const CustomModal = ({ modalData, stages, show, handleClose, stream, id }) => {

    const [title, setTitle] = useState('');
    const [content, setContent] = useState(<></>);

    const handleContent = (details) => {
        const { type, data } = details;
        const formattedTitle = `${stream.charAt(0).toUpperCase() + stream.slice(1)} - ${id}`;
        switch (type) {
            case 'timeline':
                setTitle(`Timeline for ${formattedTitle}`);
                setContent(<Timeline stages={stages} items={data} />);
                break;
            case 'history':
                setTitle(`History for ${formattedTitle}`);
                setContent(<History items={data} />);
                break;
            case 'transaction':
                setTitle(`Transactions for ${formattedTitle}`);
                setContent(<ReactJsonViewCompare oldData={data} newData={data} />);
                break;
            case 'file':
                setTitle(`${stream} File for ${formattedTitle}`);
                setContent(<Files stream={stream} items={data} />);
                break;
            case 'maptrace':
                const coordinates = data.map(({ data: { json: { lat, lon } } }) => ([lat, lon]));
                const traceViewport = data.map(({ data: { json: { lat, lon } } }) => ({ latitude: lat, longitude: lon })).pop();
                setTitle(`Trace for ${formattedTitle}`);
                setContent(coordinates.length ? <MapView
                    port={{ ...traceViewport }}
                    zoom={3}
                    route={{
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates,
                        }
                    }}
                    mapType='mapbox://styles/mapbox/streets-v11'
                /> : <></>);
                break;
            case 'location':
                const markers = data.map(({ data: { json: { lat, lon } } }) => ({ lat, lon }));
                const marker = markers.length ? markers.pop() : false;
                const locationViewport = data.map(({ data: { json: { lat, lon } } }) => ({ latitude: lat, longitude: lon })).pop();
                setTitle(`Trace for ${formattedTitle}`);
                console.log(locationViewport, marker)
                setContent(marker ? <MapView
                    markers={[{ ...marker, type: 'marker' }]}
                    port={{ ...locationViewport }}
                    zoom={3}
                    mapType='mapbox://styles/mapbox/streets-v11'
                /> : <></>);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        handleContent(modalData);
    }, [modalData]);

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <XSquare onClick={handleClose} size={30} />
            </Modal.Header>
            <Modal.Body className='h-100'>{content}</Modal.Body>
        </Modal>
    );

}

export default CustomModal;