import { Nav, Navbar } from 'react-bootstrap';
import { useContext } from 'react';
import { DataContext } from './../../context/DataContext';

const Navigation = ({ routes }) => {
    const { user, role, activeRoute, setActiveRoute, handleLogout } = useContext(DataContext);

    const DefaultRoutes = () => {
        return user
            ? <Nav className="mr-auto">
                {routes.length && routes.map((route, index) => {
                    return (
                        <Nav.Link
                            href={`/${route}`}
                            key={`navigation-${route}-${index}`}
                            onClick={() => setActiveRoute(route)}
                            className={`nav-item ${activeRoute === route ? 'active' : ''}`}
                        >
                            {`${route.charAt(0).toUpperCase()}${route.slice(1)}`}
                        </Nav.Link>
                    );
                })}
            </Nav>
            : <Nav className="mr-auto"></Nav>
    }

    const AuthenticationRoutes = () => {
        return !user
            ? <Nav>
                <Nav.Link
                    eventKey={2}
                    href="/login"
                    onClick={() => setActiveRoute('/login')}
                    className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                >
                    Sign in
                </Nav.Link>
            </Nav>
            : <Nav>
                <Nav.Link
                    eventKey={2}
                    href="/login"
                    onClick={() => {
                        handleLogout();
                        setActiveRoute('/login')
                    }}
                    className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                >
                    Sign Out
                </Nav.Link>
            </Nav>
    }

    const McColgansRoutes = () => {
        return user
            ? <Nav className="mr-auto">
                {
                    ['admin', 'user'].includes(role) ? <Nav.Link
                        href="/queue"
                        key="navigation-csv"
                        onClick={() => setActiveRoute('queue')}
                        className={`nav-item ${activeRoute === 'queue' ? 'active' : ''}`}
                    >
                        Queue
                    </Nav.Link> : <></>
                }
                {
                    ['admin', 'auditor'].includes(role) ? <Nav.Link
                        href="/report"
                        key="navigation-report"
                        onClick={() => setActiveRoute('report')}
                        className={`nav-item ${activeRoute === 'report' ? 'active' : ''}`}
                    >
                        Report
                    </Nav.Link> : <></>
                }
            </Nav>
            : <Nav className="mr-auto"></Nav>

    }

    return (
        <div className="main-navbar sticky-top bg-white">
            <Navbar className="ps-4 pr-4" collapseOnSelect expand="lg" bg="light" variant="light">
                <Navbar.Brand href="/">
                    <img alt="Logo" src="logo.png" height={30} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <McColgansRoutes />
                    <AuthenticationRoutes />
                </Navbar.Collapse>
            </Navbar>
        </div>
    )

}

export default Navigation;