import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { XSquare } from "react-bootstrap-icons";
import Files from './../../components/Files/index';
// import { PDFDocument } from 'pdfjs-dist';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import './theme.css';

const Report = ({ stream }) => {

    const { axios } = useContext(GeneralContext);
    const { role, user } = useContext(DataContext);
    const [data, setData] = useState([]);
    const [selectedLabResultData, setSelectedLabResultData] = useState([]);
    const [auditors, setAuditors] = useState([]);
    const [selectedReportData, setSelectedReportData] = useState([]);
    const [documentMetadata, setDocumentMetadata] = useState([]);
    const [documentAccess, setDocumentAccess] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const handleConfirmationClose = () => {
        setShowConfirmationModal(false)
    };

    const getStreamData = async (stream) => {
        try {
            const { data: { data } } = ["admin"].includes(role)
                ? await axios.get(`/${stream}?page=1&items=1000`)
                : await axios.get(`/${stream}/transactions-by-key/${user.address}?page=1&items=1000`);
            setData(data.map(({ data: { json } }) => (json)));
        } catch (err) {
            setData(null);
        }
    };

    const getUsers = async () => {
        try {
            const { data: { data } } = await axios.get(`/user?page=1&items=1000`);
            const users = data.map(({ data: { json } }) => json).filter(user => (user.role === 'auditor'));
            setAuditors([...users, { id: "None", address: "", role: "" }]);
        } catch (err) {
            setAuditors(null);
        }
    };

    /**
 * 
 * @param {*} payload 
 * @param {*} status 
 */
    const handleReportAction = (payload, status) => {
        setSelectedReportData({ ...payload, data: status ? payload.data.map(e => ({ ...e, status, user: user })) : payload.data });
        reportHasDocument(payload.reportIdSampleId)
        setShowConfirmationModal(true);
    }

    const reportHasDocument = async (key) => {
        const { error, data: { data } } = await axios.get(`/reports-document-metadata/transactions-by-key/${key}?page=1&items=1`);
        setDocumentMetadata(data);
    }

    useEffect(() => {
        const fetchStreamData = async () => {
            await getStreamData();
        }

        fetchStreamData().catch(console.error);
        const fetchUsersData = async () => {
            await getUsers();
        }

        fetchUsersData().catch(console.error);
    }, [])

    const columns = [
        {
            dataField: 'reportId',
            text: 'Report Id',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "10%" }),
            filter: textFilter(),
        },
        {
            dataField: 'date',
            text: 'Date',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "20%" }),
            filter: dateFilter('dd/mm/yyy'),
        },
        {
            dataField: 'sampleId',
            text: 'Sample Id',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "10%" }),
            filter: textFilter()
        },
        {
            dataField: 'sampleName',
            text: 'Sample Name',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "10%" }),
            filter: textFilter()
        },
        {
            dataField: 'sampleDescription',
            text: 'Sample Description',
            formatter: (data, index) => {
                return data;
            },
            sort: true,
            headerStyle: () => ({ width: "20%" }),
            filter: textFilter()
        },
        {
            dataField: 'user.id',
            text: 'Saved by',
            formatter: (data, index) => {
                return data;
            },
            filter: textFilter(),
            sort: true
        },
        {
            dataField: 'action2',
            text: 'Show Report',
            formatter: (data, row) => {
                const { id } = row;
                return (
                    <div className='text-center'>
                        <Button
                            key={`save-all-${id}`}
                            className="btn-info"
                            onClick={() => { handleReportAction(row) }}
                        >
                            Show Report
                        </Button>
                    </div>
                )
            },
            headerStyle: () => ({ width: "10%" }),
        }
    ]

    const confirmationColumns = [
        {
            dataField: 'date',
            text: 'Date',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'substance',
            text: 'Substance',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'value',
            text: 'Value',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'unit',
            text: 'Unit',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'comment',
            text: 'Comment',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        },
        {
            dataField: 'user.id',
            text: 'Updated by',
            formatter: (data, index) => {
                return data;
            },
            sort: true
        }
    ]

    useEffect(() => {
    }, [selectedLabResultData, , data, showConfirmationModal, documentMetadata, documentAccess])

    useEffect(() => {
        const fetchStreamData = async () => {
            await getStreamData(stream);
        }
        fetchStreamData().catch(console.error);
    }, [selectedReportData])

    return (
        <div className="d-flex flex-column m-4">
            <Modal size="lg" show={showConfirmationModal} onHide={handleConfirmationClose}>
                <Modal.Header>
                    <Modal.Title>Report Details</Modal.Title>
                    <XSquare onClick={handleConfirmationClose} size={30} />
                </Modal.Header>
                <Modal.Body className='h-100'>
                    <Row>
                        <div className="col-lg-12 col-md-12">
                            <Files className="p-3" filters={false} pagination={false} items={documentMetadata} stream='reports' />
                        </div>
                    </Row>
                    {
                        selectedReportData?.data && <BootstrapTable
                            fluid
                            bootstrap4
                            keyField='id'
                            columns={confirmationColumns}
                            data={selectedReportData.data}
                            filterPosition="top"
                        />

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Col>
                        <Row className="d-flex justify-content-end flex-row flex-nowrap align-items-center">
                            <Button key={`save-on-chain`} className="btn-info" onClick={() => { setShowConfirmationModal(false) }} >Close</Button>
                        </Row>
                    </Col>
                </Modal.Footer>
            </Modal>
            <div className='row'>
                <div className='col-12'>
                    <div>
                        {
                            (data && data.length) ? <BootstrapTable
                                fluid
                                bootstrap4
                                keyField='id'
                                columns={columns}
                                data={data}
                                filter={filterFactory()}
                                filterPosition="top"
                                pagination={paginationFactory()}
                            /> : (
                                <div className="no-data p-4 m-4">
                                    <div className="alert alert-info text-center">
                                        No data available
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Report;