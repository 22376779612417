import { createContext } from "react";
import axios from 'axios';

const {
    REACT_APP_REST_PORT,
    REACT_APP_TOKEN,
    REACT_APP_MAPS_KEY,
    REACT_APP_REST_PROTOCOL,
    REACT_APP_REST_HOST
} = process.env

export const env = {
    maps: REACT_APP_MAPS_KEY
}

axios.defaults.baseURL = `${REACT_APP_REST_PROTOCOL || "http"}://${REACT_APP_REST_HOST || "localhost"}:${REACT_APP_REST_PORT || 5000}`;
axios.defaults.headers.common['Authorization'] = `Bearer ${REACT_APP_TOKEN}`;

export default axios;

export const GeneralContext = createContext();