import { useState, useContext, useEffect } from "react";
import { GeneralContext } from './../../context/GeneralContext';
import { DataContext } from './../../context/DataContext';
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const { axios } = useContext(GeneralContext);
    const { handleLogin } = useContext(DataContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);

    const handleUsername = (e) => {
        setUsername(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const getUser = async () => {
        const { data: { error, data: user } } = await axios.post("/user/login", { data: { username, password } });
        if (!error && user) {
            handleLogin(user);
            navigate("/");
        } else {
            setErrorLogin(true);
        }
    };

    useEffect(() => {
        console.log(errorLogin)
    }, [errorLogin])

    return (
        <div className="main-content-container container-fluid p-4">
            <div className="row">
                <div className="col-lg-4 col-md-4"></div>
                <div className="col-lg-4 col-md-4">
                    <div className="wrapper fadeInDown">
                        <div id="formContent">
                            <form>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        onChange={(e) => handleUsername(e)}
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        aria-describedby="username"
                                        placeholder="Enter username"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        onChange={(e) => handlePassword(e)}
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Password"
                                    />
                                </div>
                                {errorLogin && <small id="emailHelp" className="form-text text-danger mb-3">Invalid login credentials. Please try logging in again ! </small>}
                                <div onClick={() => getUser()} className="btn btn-primary">Submit</div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4"></div>
            </div>
        </div >
    );
};

export default Login;