import './theme.css';

const FileUpload = ({ handleFileSelect, saveData, title }) => {

    return (
        <div className="wrapper">
            <div id="formContent">
                <form>
                    <div className="form-group">
                        <label>{title}</label>
                        <input
                            onChange={handleFileSelect}
                            type="file"
                            className="form-control"
                            id="file"
                            aria-describedby="file"
                        />
                        <small id="file" className="form-text text-muted">{title} file with data for extraction</small>
                    </div>
                    {/* <div onClick={() => { saveData() }} className="btn btn-primary">Submit</div> */}
                </form>
            </div>
        </div>
    )
}

export default FileUpload;